/*! MainAppSearchPolygon */

html,
body,
#root,
.App {
    width: 100%;
    height: 100%;
    overflow-x: auto;
    overflow-y: auto;
}


/* #root {
    min-height: 100vh;
} */

.Main-title {
    margin-bottom: 10px;
    letter-spacing: 5px;
}

.Live-demo {
    margin-top: 10px;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    background-size: cover;
}


/* ? + used on multiple pages*/

.Background-link-info {
    background-image: url("./assets/squares.png");
    /* background-color: "midnightblue"; */
    min-height: 100vh;
}

.App-header {
    background-image: url("./assets/squares.png");
    background-color: #1a2740;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    /* align-items: center;
justify-content: center; */
    font-size: calc(10px + 2vmin);
    color: white;
}


/*? meant to be for styling all inputs under app header*/

.App-header input {
    height: 50px;
    border-style: solid;
    border-radius: 10px;
}

.App-header button {
    height: 50px;
}

.App-link {
    color: #09d3ac;
}

.submit-question-container {
    padding: 2em 4em;
}

input::-webkit-input-placeholder {
    text-align: center;
}

input:-moz-placeholder {
    /* Firefox 18- */
    text-align: center;
}

input::-moz-placeholder {
    /* Firefox 19+ */
    text-align: center;
}

input:-ms-input-placeholder {
    text-align: center;
}


/*! Question Submission Page */

.Demo-video {
    display: block;
    margin: auto;
    max-height: 490px;
    max-width: 920px;
}

.Enter-question-styling::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    font-size: medium;
}

input.Text-input-questionsubmissionpage[type=text] {
    padding: 0% 15% 0% 15%;
    box-sizing: border-box;
    caret-color: #db143c;
}

input#Text-input-keywordsearch[type=text] {
    padding: 0% 35% 0% 35%;
    box-sizing: border-box;
    caret-color: #db143c;
}

.What-is-search-polygon {
    margin-top: 30px;
    margin-bottom: 40px;
    font-style: italic;
    color: gainsboro;
    font-size: 20px;
}

.Color-greeninput::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: green;
}

.Color-redinput::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: red;
}

.item {
    /* width: 50px;
    text-align: justify; */
    position: relative;
    display: inline-block;
    text-align: center;
    margin-left: 90%;
}

.caption {
    position: absolute;
    width: 100%;
    text-align: center;
    bottom: 0;
    left: 0;
    font-size: medium;
    margin-left: 40%;
}

#heart img {
    left: 0;
    right: 0;
    margin: 0 auto;
    max-width: 50px;
    max-height: 50px;
    width: auto;
    height: auto;
    /* margin-right: 100px; */
    /* display: block; */
}

#heart img.bottom {
    animation-name: heartFadeInOut;
    animation-iteration-count: infinite;
    animation-duration: 6s;
    display: block;
    padding: 45px;
    /* margin: 0 auto; */
}

@keyframes heartFadeInOut {
    0% {
        transform: scale(1);
    }
    25% {
        transform: scale(.97);
    }
    35% {
        transform: scale(.9);
    }
    45% {
        transform: scale(1.1);
    }
    55% {
        transform: scale(.9);
    }
    65% {
        transform: scale(1.1);
    }
    75% {
        transform: scale(1.03);
    }
    100% {
        transform: scale(1);
    }
}


/* .explanation-colour-red {
    color: red
} */

.Or-text-styling {
    text-decoration: underline;
    margin-top: 0px;
    color: #c3c3c3;
}

.bolt-quick-sesssion-home {
    font-size: x-large;
}

.Quick-session-button {
    font-size: large;
    font-weight: 900;
    margin-bottom: 30px;
    color: #08085c;
    border-radius: 10px;
}

.twitter {
    margin-bottom: 34px
}

.View-trending {
    display: inline;
}


/* Position of the shapes on the page -top moves shapes up e.g.*/

.View-shapes {
    position: relative;
    top: -20px;
    left: 40px;
}

.fadein img {
    position: absolute;
    top: 0px;
    left: 0px;
    -webkit-animation-name: fade;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-duration: 15s;
    animation-name: fade;
    animation-iteration-count: infinite;
    animation-duration: 15s;
}

@-webkit-keyframes fade {
    0% {
        opacity: 0;
    }
    20% {
        opacity: 1;
    }
    33% {
        opacity: 1;
    }
    53% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

@keyframes fade {
    0% {
        opacity: 0;
    }
    20% {
        opacity: 1;
    }
    33% {
        opacity: 1;
    }
    53% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

#diamond {
    width: 65px;
    height: 65px;
}

#hexagon {
    width: 65px;
    height: 65px;
    -webkit-animation-delay: -10s
}

#triangle {
    width: 65px;
    height: 65px;
    -webkit-animation-delay: -5s;
}

.Trending-heading-style {
    font-size: 33px;
    color: #6034ad;
}


/*! TrendingActivityPage */

.coming-soon {
    margin: auto;
    width: 50%;
    border: 3px solid green;
    padding: 10px;
    margin-top: 7%;
    text-align: center;
}

.create {
    margin: auto;
    width: 50%;
    border: 3px solid rgb(128, 19, 0);
    padding: 10px;
    margin-top: 60px;
    text-align: center;
    color: #05557b;
    /* font-size: 30px; */
}

.loading {
    margin: auto;
    text-align: center;
    color: blue;
    margin-top: 50px;
}

.loading {
    animation-name: loadingpulse;
    animation-iteration-count: infinite;
    animation-duration: 5s;
    display: block;
}

@keyframes loadingpulse {
    0% {
        transform: scale(1);
    }
    25% {
        transform: scale(.97);
    }
    35% {
        transform: scale(.9);
    }
    45% {
        transform: scale(1.1);
    }
    55% {
        transform: scale(.9);
    }
    65% {
        transform: scale(1.1);
    }
    75% {
        transform: scale(1.03);
    }
    100% {
        transform: scale(1);
    }
}

.Radar-chart-trending-page {
    display: block;
    margin-left: auto;
    margin-right: auto;
    /* padding: 0px 0px 0px 250px; */
    margin-top: 30px;
    margin-bottom: 25px;
    width: 50%;
}

.Trending-page-keyword-title {
    margin-right: 50px;
    margin-left: 100px;
    display: inline-block;
    margin-bottom: 5px;
    margin-top: 55px;
    /* display: block; */
    /* margin-left: auto;
    margin-right: auto; */
    color: green;
    text-align: center
}

.Trending-page-reaction-count {
    margin-left: 100px;
    display: inline-block;
    margin-bottom: 5px;
    margin-top: 55px;
    /* display: block; */
    /* margin-left: auto;
    margin-right: auto; */
    color: blue;
    text-align: center;
}


/*! AboutPage */


/* ? + Also completed a lot of inline styling on the page */

.AboutPage-fullpage {
    height: 100vh;
    min-height: 100vh;
}

.About-page-benefits {
    font-size: x-large;
    border-style: inset;
    padding: 20px 10px 30px 60px;
    margin: 0px 30px 30px 30px;
    list-style-type: none;
}

.Colour-animation {
    animation: color-change 4s infinite;
}

@keyframes color-change {
    0% {
        color: red;
    }
    50% {
        color: rgb(0, 26, 255);
    }
    100% {
        color: rgb(255, 44, 44);
    }
}


/*! LinkInfoPage */

.Wrapping-tabletext {
    word-break: break-word;
}

.Padding-tablelinks {
    padding-right: 10px;
    padding-left: 90px;
}

.Padding-reactionbutton {
    padding-right: 5px;
}

a.Link-list:link {
    color: #d90404;
    font-size: x-large;
    /* text-decoration: none; */
}

a.Link-list:visited {
    color: #42db00;
    /* text-decoration: none; */
}

a.Link-list:hover {
    color: #42db00;
    /*? text-decoration: none; */
}


/*?  for grid template the last 1fr doesn't correspond to a tag but helps to add space */

td.Reaction-buttons {
    padding: 6px 0px 0px;
    display: grid;
    grid-template-columns: 1fr 2fr 2fr 1fr;
    grid-gap: 4px;
}

.Up-react-buttons-style {
    border-radius: 5px;
    font-size: x-large;
    color: rgb(8, 68, 43);
    font-weight: 900;
}

.Down-react-buttons-style {
    border-radius: 5px;
    font-size: x-large;
    color: rgb(182, 25, 25);
    font-weight: 900;
}

li.Questions-link-info {
    padding: 2px;
    display: grid;
    grid-template-columns: 1fr 7fr 2fr 4fr;
    grid-gap: 5px;
}

.Back-page-navigation-button {
    height: 50px;
    width: 120px;
    font-size: 20px;
    color: blue;
    letter-spacing: 3px;
    border-radius: 5px;
    background-color: lightgrey;
    margin-bottom: 30px;
    margin-top: 40px;
}

.Radar-chart {
    /* padding: 100px 100px 100px 100px; */
    display: block;
    margin-left: auto;
    margin-right: auto;
    /* margin-left: 100px; */
    /* padding: 0px 0px 0px 250px; */
    margin-top: 10px;
    margin-bottom: 50px;
    width: 50%;
}


/* .rv-xy-plot__series--label-text {


      
} */


/*  */


/*! QuestionsListPage */

.Bulletpoint-spacing {
    position: relative;
    right: -190px;
}

.Question-listspacing {
    padding-right: 400px;
}

.Next-page-navigation-button {
    height: 50px;
    width: 120px;
    font-size: 20px;
    color: blue;
    letter-spacing: 3px;
    border-radius: 5px;
    background-color: lightgrey;
    margin-top: 30px;
}

.Styling-explanation-text {
    margin-top: 0px;
    margin-bottom: 10px
}


/* ! QuestionLinkPage */

.Links-styling {
    font-size: smaller;
    padding: 10px 40px 0px 0px;
    word-break: break-all;
}

.keyword-bottomspacing {
    margin-bottom: 6px;
    color: crimson;
}

input.Text-input-questionlistpage[type=url] {
    padding: 0% 5% 0% 5%;
    box-sizing: border-box;
    caret-color: #db143c;
}

.Link-page-navigation-button {
    height: 50px;
    width: 140px;
    font-size: 20px;
    color: blue;
    letter-spacing: 3px;
    border-radius: 5px;
    background-color: lightgrey;
    margin-left: 40px;
}

.Question-styling {
    margin-top: 13px;
    margin-bottom: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.Grid-container {
    display: grid;
    grid-template-columns: 0.5fr 2fr 1fr 1fr;
    height: 43px;
}

.Search-engine {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    overflow: hidden;
    font-size: 23px;
}

.Search-engine {
    animation-name: globepulse;
    animation-iteration-count: infinite;
    animation-duration: 5s;
    display: block;
}

@keyframes globepulse {
    0% {
        transform: scale(1);
    }
    25% {
        transform: scale(.97);
    }
    35% {
        transform: scale(.9);
    }
    45% {
        transform: scale(1.1);
    }
    55% {
        transform: scale(.9);
    }
    65% {
        transform: scale(1.1);
    }
    75% {
        transform: scale(1.03);
    }
    100% {
        transform: scale(1);
    }
}

.Search-engine:hover {
    transform: scale(1.2);
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
}


/* ! Session Page */

.Spacing-bullet-points {
    list-style-type: none;
    margin: 10px;
    padding: 0;
}

.Delete-session-button {
    width: fit-content;
    font-size: medium;
}

.Session-delete-button {
    height: 50px;
    width: 120px;
    font-size: 20px;
    color: blue;
    letter-spacing: 1px;
    border-radius: 5px;
    background-color: #fcffff;
    ;
    margin-bottom: 5px;
    /* margin-top: 40px; */
    float: left
}

.Session-share-button {
    margin-top: 20px;
    border-radius: 50px;
    font-size: large;
    font-weight: 600;
}

.Session-share-response {
    font-size: medium;
}

.Session-rename-input {
    width: 600px;
    margin-bottom: 30px
}

.Dropdown-rename {
    width: 170px;
    height: 50px;
    font-size: medium;
    font-weight: 500;
}

.Session-save-button {
    background-color: green;
    float: right;
    height: 50px;
    width: 130px;
    font-size: 17px;
    /* letter-spacing: 1px; */
}

.Session-save-text {
    color: white;
    font-weight: bolder
}

.Session-edit-button {
    background-color: #d0640c;
    float: right;
    height: 35px;
    width: 90px;
    font-size: 18px;
    letter-spacing: 1px;
}

.Session-edit-text {
    color: white;
    font-weight: bolder
}


/* !Edit Page */

.Finish-edit-text {
    font-size: x-large;
    color: #1f558e;
    font-weight: 600;
}

.Finish-edit-button {
    margin-bottom: 50px;
    margin-top: 10px;
    border-radius: 30px;
}

.Heading-session-edit {
    font-size: xxx-large;
}

.Add-keyword-button-style {
    margin-top: 20px;
    font-size: medium;
    font-weight: 800;
    border-radius: 9px;
    color: #08085c;
}

.Add-link-button {
    width: 110px;
    font-size: medium;
    font-weight: 800;
    border-radius: 9px;
    color: #08085c;
}

.Arrow-animation-add-keyword {
    animation-name: globepulse;
    animation-iteration-count: infinite;
    animation-duration: 5s;
    display: block;
}

@keyframes globepulse {
    0% {
        transform: scale(1);
    }
    25% {
        transform: scale(.97);
    }
    35% {
        transform: scale(.9);
    }
    45% {
        transform: scale(1.1);
    }
    55% {
        transform: scale(.9);
    }
    65% {
        transform: scale(1.1);
    }
    75% {
        transform: scale(1.03);
    }
    100% {
        transform: scale(1);
    }
}


/* ! MOBILE VIEW ADAPTATIONS */

@media only screen and (max-width: 600px) {
    /* ! QuestionSubmissionPage */
    .Demo-video {
        height: 250px;
        margin: 20px 0px 0px 0px;
    }
    .submit-question-container {
        padding: 2em 1em;
    }
    input[type=text] {
        padding: 0% 8% 0% 8%;
    }
    input#Text-input-keywordsearch[type=text] {
        padding: 0% 4% 0% 4%;
    }
    .item {
        /* width: 50px;
      text-align: justify;
        position: relative;
      display: inline-block;
      text-align: center; */
        margin-left: 71%;
        width: 15%;
    }
    .caption {
        position: absolute;
        width: 100%;
        text-align: center;
        bottom: 0;
        left: 0;
        font-size: 13px;
        margin-left: 35%;
    }
    #heart img.bottom {
        animation-name: heartFadeInOut;
        animation-iteration-count: infinite;
        animation-duration: 4s;
        display: block;
        padding: 35px;
        margin: 0 auto;
    }
    #heart img {
        /* left: 0;
      right: 0;
      margin: 0 auto; */
        max-width: 40px;
        max-height: 40px;
        width: auto;
        height: auto;
        /* margin-right: 100px; */
        /* display: block; */
    }
    .Or-text-styling {
        font-size: large
    }
    .bolt-quick-sesssion-home {
        font-size: large;
    }
    .Quick-session-button {
        font-size: medium;
        font-weight: 700;
        margin-bottom: 20px;
    }
    #diamond {
        width: 50px;
        height: 50px;
    }
    #hexagon {
        width: 50px;
        height: 50px;
        -webkit-animation-delay: -10s
    }
    #triangle {
        width: 50px;
        height: 50px;
        -webkit-animation-delay: -5s;
    }
    .Trending-heading-style {
        font-size: 23px;
    }
    /* ! AboutPage */
    .What-is-search-polygon {
        font-size: 15px !important;
        margin-top: 15px;
        margin-bottom: 20px;
    }
    .About-page-benefits {
        font-size: 17px;
        padding: 20px 2px 30px 2px;
        margin: 0px 30px 30px 30px;
    }
    /* ! QuestionLinkPage */
    input.Text-input-questionlistpage[type=url] {
        padding: 0% 15% 0% 15%;
    }
    .Grid-container {
        display: grid;
        grid-template-columns: 0fr 2fr 1fr 1fr;
        height: 36px;
    }
    .Search-engine {
        font-size: 17px;
    }
    .Question-styling {
        margin-top: 17px
    }
    /* ! Link info page */
    .Wrapping-tabletext {
        word-break: break-all;
    }
    .Padding-tablelinks {
        padding-right: 5px;
        padding-left: 25px;
    }
    .Padding-reactionbutton {
        padding-right: 5px;
    }
    a.Link-list:link {
        color: #d90404;
        font-size: smaller;
        /* text-decoration: none; */
    }
    .Radar-chart {
        /* padding: 0px 0px 0px 30px; */
        margin-right: 200px;
        margin-top: 0px;
        /* width: 5px; */
    }
    /* SMALL MEDIA QUERY VERSION- NOTE TO MAKE CLEAR */
    .Radar-chart-trending-page {
        margin-right: 200px;
        margin-top: 0px;
        margin-bottom: 25px
    }
    .Trending-page-keyword-title {
        font-size: larger;
        margin-right: 20px;
        margin-left: 30px;
        display: inline-block;
        margin-bottom: 20px;
        margin-top: 40px;
        /* display: block; */
        /* margin-left: auto;
      margin-right: auto; */
        color: green;
        text-align: center
    }
    .Trending-page-reaction-count {
        font-size: larger;
        margin-left: 10px;
        margin-right: 10px;
        display: inline-block;
        margin-bottom: 20px;
        margin-top: 40px;
        /* display: block; */
        /* margin-left: auto;
      margin-right: auto; */
        color: blue;
        text-align: center;
    }
    /* !QuestionsListPage */
    .Bulletpoint-spacing {
        position: relative;
        right: -20px;
    }
    .Question-listspacing {
        padding-right: 90px;
        font-size: large;
        padding-bottom: 6px;
    }
    /* ! Session Page */
    .App-header button {
        height: 40px;
    }
    .Session-save-button {
        height: 38px;
        width: 100px;
        font-size: 13px;
        /* letter-spacing: 1px; */
    }
    .Session-edit-button {
        background-color: #d0640c;
        height: 30px;
        width: 90px;
        font-size: 15px;
        border-radius: 5px;
    }
    .Delete-session-button {
        /* width: fit-content; */
        font-size: small;
    }
    .Position-delete-button {
        margin-left: 72%
    }
    .Session-delete-button {
        height: 40px;
        width: 90px;
        /* font-size: 20px; */
        color: blue;
        letter-spacing: 1px;
        border-radius: 5px;
        margin-bottom: 4px;
        /* margin-top: 40px; */
    }
    .Session-rename-input {
        width: 370px
    }
    .Dropdown-rename {
        width: 130px;
        height: 40px;
        font-size: small;
    }
    /* !Edit Page */
    .Finish-edit-text {
        font-size: large;
        color: #1f558e;
        font-weight: 500;
        margin-bottom: 30px;
    }
    .Heading-session-edit {
        font-size: x-large
    }
    .Add-keyword-button-style {
        margin-top: 10px;
    }
    .Add-keyword-button-style {
        margin-top: 20px;
        font-size: smaller;
        font-weight: 500;
        border-radius: 9px;
        width: 100px;
    }
    .Add-link-button {
        width: 90px;
        font-size: smaller;
        font-weight: 500;
    }
}